<template>
  <div>
    <v-btn
      @click="
        decrypt(
          '7A9fMT0mF39YxQJTthou9YXD3K+Vbr3KLvWOvVSC5II=',
          'F&01SxAz}9{]iB'
        )
      "
      color="success"
      >text</v-btn
    >
    <v-btn @click="dec()" color="success">text</v-btn>
  </div>
</template>

<script>
// var CryptoJS = require("crypto-js");
import CryptoJS from "crypto-js";
var JsonFormatter = {
  stringify: function (cipherParams) {
    // create json object with ciphertext
    var jsonObj = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    // optionally add iv or salt
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }
    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    }
    // stringify json object
    return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr);
    // extract ciphertext from json object, and create cipher params object
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
    });
    // optionally extract iv or salt
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    }
    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    }
    return cipherParams;
  },
};
export default {
  data() {
    return {
      pass: "7A9fMT0mF39YxQJTthou9YXD3K+Vbr3KLvWOvVSC5II=",
      key: "F&01SxAz}9{]iB",
    };
  },
  methods: {
    decrypt(ciphertext, key) {
      // let text = CryptoJS.lib.WordArray.create(
      //   CryptoJS.enc.Utf8.parse(ciphertext).words
      // );

      let text = CryptoJS.MD5(ciphertext);

      var keyHex = CryptoJS.enc.Utf8.parse(key);

      var decrypted = CryptoJS.AES.decrypt(
        { ciphertext: text },
        keyHex.toString(),
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // var decrypted = CryptoJS.AES.decrypt(ciphertext, keyHex);
      console.log(decrypted.toString());
    },
    dec() {
      let key = CryptoJS.enc.Utf8.parse(this.key);

      key = CryptoJS.MD5(this.key)
      let pass = CryptoJS.lib.WordArray.create(
        CryptoJS.enc.Utf8.parse(this.pass).words
      );

      var decrypted = CryptoJS.AES.decrypt(this.pass , key, {
        // format: JsonFormatter,
        // iv:'',
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.NoPadding,
        // key: key
      });

      console.log(decrypted.toString());
    },
  },
};
</script>

<style></style>
